<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-0 justify-content-center">
      <div class="col-12 col-lg-10 m-0 p-0">
        <div class="row m-0 p-2 bg-main justify-content-center">
          <img class="img-fluid" src="@/assets/PWB.svg" alt="" />
        </div>
        <!-- -------------------- -->
        <div
          class="col-12 mt-3 shadow p-3 mb-3 bg-white rounded"
          v-if="data.product !== null"
        >
          <div class="row my-2 p-3 align-items-center">
            <div class="col-2 text-center">
              <span
                v-if="data.img.length !== 0"
                @click="blackfull()"
                v-html="$svg_icon.Chevron_left"
                type="button"
                class="btn  bt-transparent "
              ></span>
            </div>
            <div class="col-8 text-center">
              <img
                v-if="data.img.length === 0"
                src="@/assets/photo.png"
                alt=""
                class="img-fluid img"
                style="max-height:50vh ;"
              />
              <img
                v-else
                :src="`${$cons.API_URL_IMG}${data.img[index]}`"
                alt=""
                class="img-fluid img"
                style="max-height:50vh ;"
              />
            </div>
            <div class="col-2 text-center">
              <span
                v-if="data.img.length !== 0"
                @click="addfull()"
                v-html="$svg_icon.Chevron_right"
                type="button"
                class="btn bt-transparent "
              ></span>
            </div>
            <!-- ------ dot ----- -->
            <div class="col-12 my-2">
              <div class="row justify-content-center">
                <div
                  class="mx-1 p-0"
                  v-for="(e, i) in data.img"
                  :key="i + 'img'"
                >
                  <div
                    type="button"
                    class="dot"
                    :class="[i === index ? ' active' : '']"
                    @click="index = i"
                  ></div>
                </div>
              </div>
            </div>

            <!-- ----------- -->
            <div class="col-12 mt-4 p-0">
              <span class="badge badge-dark px-3 py-2">{{
                data.product.brand
              }}</span>

              <p class="productname my-3">{{ data.product.prNameTH }}</p>
              <p class="productsku">รหัสสินค้า: {{ data.product.skCode }}</p>

              <hr />

              <p class="productsdesp">
                <span
                  class="productsdesp"
                  v-html="data.product.shortDesc1TH"
                ></span>
              </p>
            </div>
          </div>
        </div>
        <!-- ------------------- -->
        <div class="col-12 text-center">
          <p class="productname my-3">เกี่ยวกับสินค้า</p>
        </div>
        <!-- ----------------- -->
        <div
          class="col-12 my-3 shadow p-3 mb-3 bg-white rounded"
          v-if="data.product !== null"
          style="min-height:50vh ;"
        >
          <div class="row my-3 p-2">
            <div
              class="col-6 text-center border-buttom-detail pb-3"
              :class="[index_detail === e.index ? 'active' : '']"
              type="button"
              v-for="(e, i) in detail_list"
              :key="i + `detail`"
              @click="index_detail = e.index"
            >
              {{ e.name }}
            </div>
            <!-- ------------------------ -->
            <div class="col-12 mt-3 " v-if="index_detail === 0">
              <div
                class="m-0 p-0 w-100"
                v-if="$serviceMain.chechnull(data.product.longDescTH) !== ''"
              >
                <div class=" detail" v-html="data.product.longDescTH"></div>
              </div>
              <p v-else class="text-center  my-5">ไม่พบข้อมูล</p>
            </div>
            <div class="col-12 mt-3 " v-if="index_detail === 1">
              <div class="m-0 p-0 w-100" v-if="data.attr.length !== 0">
                <div
                  class="row p-1 "
                  v-for="(e, i) in data.attr"
                  :key="i"
                  :class="even(i) ? '' : 'att-even'"
                >
                  <!-- {{e}} -->
                  <div class="col-6 font-600">{{ e.PropName }}</div>
                  <div class="col-6">{{ e.PropDetail }}</div>
                </div>
              </div>
              <p v-else class="text-center  my-5">ไม่พบข้อมูล</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getpimenrichproduct_qr, getpimenrichproduct } from "@/api/Product";
export default {
  data() {
    return {
      index: 0,
      index_detail: 0,
      data: {
        product: null,
        attr: [],
        img: [],
      },
      detail_list: [
        { index: 0, name: "รายละเอียด" },
        { index: 1, name: "ข้อมูลเฉพาะ" },
      ],
    };
  },
  mounted() {
    this.get_data();
  },
  methods: {
    addfull() {
      let len = this.data.img.length;

      this.index++;

      if (this.index > len - 1) {
        this.index = 0;
      }
    },

    blackfull() {
      let len = this.data.img.length;

      this.index--;

      if (this.index < 0) {
        this.index = len - 1;
      }
    },
    async get_data() {
      if (!!this.$route?.params?.skcode) {
        // ----- get data -----
        let getdata = await getpimenrichproduct_qr({
          mode: "data",
          skCode: this.$route?.params?.skcode,
        });

        this.data.product = getdata.data[0];

        // ------------- attr ---
        let getattr = await getpimenrichproduct({
          mode: "attr",
          skcode: this.$route?.params?.skcode,
        });

        let attr = [];
        for await (let a of getattr.data) {
          let check = await this.cutname(a.PropName);

          let checkpop = this.$serviceMain.chechnull(a.PropDetail);

          if (check !== null && checkpop !== "") {
            a.PropName = check;
            attr.push(a);
          }
        }
        this.data.attr = attr;

        this.set_img();
      } else {
        this.$serviceMain.showErrorAlert(this, `skCode Not Found`);
      }
    },
    even(i) {
      if (i % 2 == 0) {
        return true;
      } else {
        return false;
      }
    },
    async cutname(e) {
      return new Promise((resolve, reject) => {
        let st = e.substring(0, 1);

        if (st == "") {
          resolve(null);
        } else {
          let arr = [""];

          let datareturn;

          if (arr.includes(st)) {
            datareturn = e.substring(1, e.length);
          } else {
            datareturn = e;
          }

          resolve(datareturn);
        }
      });
    },
    async set_img() {
      try {
        let img = [];
        if (!!this.data?.product?.images) {
          let images = String(this.data?.product?.images).split("|");
          for await (let v of images) {
            if (!!v) {
              img.push(v);
            }
          }

          this.data.img = img;
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>

<style scoped>
.close {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #000;
}
.box {
  background-color: #fff;

  border-radius: 7px;
}
.imgnow {
  border: 1px solid #b8292f;
}
.go {
  position: absolute;
  right: -20px;
  transform: translateY(50%);
}
.black {
  position: absolute;
  left: -20px;
  transform: translateY(50%);
}
.productname {
  position: relative;

  font-size: 1.5em;
  line-height: 1em;
  font-weight: 700;
}
.productsdesp {
  font-size: 1.1em;
}
.productsku {
  font-size: 1em;
  color: gray;
}
.att-even {
  background-color: rgb(228, 228, 228);
}
.detail::v-deep img {
  max-width: 100%;
  height: auto;
}
.productprice {
  color: #b8292f;
}
.productprice > span:last-of-type {
  color: gray;
}
.productprice > span:first-of-type {
  color: #b8292f;
}
.productoldprice {
  text-decoration: line-through;
  color: #666;
  margin: 0;
  display: inline;
}
.openstmodal {
  text-decoration: underline;
  color: #000;
}
.productplpp {
  overflow-y: scroll;
  padding: 0.5em 0.5em 0 0.5em;
  margin-bottom: 0;
  background-color: #ebebeb;
  border-radius: 0;
  font-size: 1em;
  color: #000;
}
.badge_pdp {
  width: 100%;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
}
.followcondi {
  display: block;

  font-size: 0.8em !important;
  color: #666;
}
.box-action {
  position: absolute;

  height: 10%;
  width: 100%;
  bottom: 0px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  color: #666;
  /* background: #666; */
}
</style>
